import React, { Suspense, useEffect } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { wtGetFromLocalStorage } from './helpers/commons/LocalStorage'
import { AppConstants } from "../src/helpers/commons/constants";
import Checkout from './views/pages/Checkout';
import Upsell from './views/pages/Upsell';
import Introduction from './views/pages/AstroGuide/Introduction';


function App() {

  useEffect(() => {
    document.title = AppConstants.app_name; // Set the title dynamically
  }, []);


  const AuthenticatedRoutes = ({ children }) => {
    const authUser = wtGetFromLocalStorage('authUser')
    return authUser ? children : <Navigate to="/" />
  }

  const CheckLogin = () => {
    const authUser = wtGetFromLocalStorage('authUser')
    return authUser ? <Navigate to="/AstroGuide/Introduction" replace /> : <Login />
  }

  const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
  // Pages
  const Home = React.lazy(() => import('./views/pages/Home/Home'))
  const StartQuiz = React.lazy(() => import('./views/pages/StartQuiz'))
  const Login = React.lazy(() => import('./views/pages/Login/Login'))
  const Register = React.lazy(() => import('./views/pages/Register/Register'))
  const SignUp = React.lazy(() => import('./views/pages/SignUp/SignUp'))
  const ForgotPassword = React.lazy(() => import('./views/pages/ForgotPassword/ForgotPassword'))
  const ResetPassword = React.lazy(() => import('./views/pages/ResetPassword/ResetPassword'))
  const Page404 = React.lazy(() => import('./views/pages/Page404/Page404'))

  return (
    <Suspense>
      <BrowserRouter>
        <Routes>

          <Route exact path="/" name="Astro Landing Page" element={<Home />} />
          <Route exact path="/Start-Quiz" name="Start Quiz" element={<StartQuiz />} />
          <Route exact path="/Checkout" name="Checkout" element={<Checkout />} />
          <Route exact path="/Upsell" name="Upsell" element={<Upsell />} />

          {/* TO DO: Decide about these pages */}
          <Route exact path="/AstrologicalGuide" name="Astrological Guide" element={<Page404 />} />
          <Route exact path="/PalmistryReading" name="Palmistry Reading" element={<Page404 />} />
          <Route exact path="/About" name="About Us Page" element={<Page404 />} />
          <Route exact path="/LearnMore" name="Learn More" element={<Page404 />} />
          <Route exact path="/Reviews" name="Reviews" element={<Page404 />} />
          {/* TO DO */}


          <Route exact path="/Login" name="Login Page" element={<CheckLogin />} />
          <Route exact path="/Register" name="Register Page" element={<Register />} />
          <Route exact path="/Sign-Up" name="Sign Up" element={<SignUp />} />
          <Route exact path="/Forgot-Password" name="Forgot Password" element={<ForgotPassword />} />
          <Route exact path="/Reset-Password" name="Reset Password" element={<ResetPassword />} />

          <Route
            path='*'
            element={
              <AuthenticatedRoutes>
                <DefaultLayout />
              </AuthenticatedRoutes>
            }
          />

          <Route exact path="/404" name="Not Found" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}

export default App
