import React from 'react';
import { Spin } from 'antd';

const Loading = () => {
    return (
        <Spin />
    );
}

export default Loading;
