import React, { useEffect, useState } from 'react';
import { Col, Steps, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Loading from '../../../helpers/commons/loader';
import { wtGetFromLocalStorage, wtSaveToLocalStorage } from '../../../helpers/commons/LocalStorage';
import { useNavigate } from 'react-router-dom';
import { AppConstants } from '../../../helpers/commons/constants';
import '../StartQuiz/Quiz.css';
import { wtApiCall } from '../../../helpers/commons/ApiWrapper';
import { showErrorNotification, showSuccessNotification } from '../../../helpers/commons/notifications';
import { Footer } from 'antd/es/layout/layout';
import Level109Quiz from './Level109Quiz';
import Level110Quiz from './Level110Quiz';
import Level111Quiz from './Level111Quiz';
import Level112Quiz from './Level112Quiz';
import { currentDateTime } from '../../../helpers/commons/moment';
const { Text } = Typography;

const Upsell = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const authTokenWebsite = wtGetFromLocalStorage('authTokenWebsite', '');
    const quiz = wtGetFromLocalStorage('quiz', null);

    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState([]);
    const [specialOffer, setSpecialOffer] = useState([]);
    const [expertConsultationOffer, setExpertConsultationOffer] = useState([]);
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [step, setStep] = useState(1);

    const items = [
        {
            title: <span style={{ fontSize: 12 }} dangerouslySetInnerHTML={{ __html: t('upsell_1-sections_title-1') }} />
        },
        {
            title: <span style={{ fontSize: 12 }} dangerouslySetInnerHTML={{ __html: t('upsell_1-sections_title-2') }} />
        },
        {
            title: <span style={{ fontSize: 12 }} dangerouslySetInnerHTML={{ __html: t('upsell_1-sections_title-3') }} />
        },
    ];

    useEffect(() => {
        if (authTokenWebsite === '' || quiz === null) {
            navigate('/');
        }
        else {
            if (quiz.level < 109) {
                navigate('/');
            }
            if (quiz.level === 111) {
                setStep(2);
            }
            if (quiz.level === 112) {
                setStep(3);
            }

        }
    }, [authTokenWebsite, quiz]);

    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        getPaymentPlans();
        getCountries();
        getSpecialOfferPlan();
        getExpertConsultationOfferPlan();
        if (quiz.level < 109) {
            navigate('/Checkout')
        }
        if (quiz.level < 106) {
            navigate('/')
        }
    }, []);

    const getPaymentPlans = () => {
        try {
            setIsLoading(true)
            wtApiCall(
                'plans?service_type=[3,4]&is_palm_mistry=1',
                {},
                'get',
                (response) => {
                    if (response && response.data) {
                        setPlans(response.data);
                        setSelectedPlan([response.data[0].id])
                    }
                    setIsLoading(false)
                },
                (error) => {
                    showErrorNotification(error.message)
                    setIsLoading(false)
                },
                true
            )
        } catch (error) {
            showErrorNotification('Error processing!', error);
            setIsLoading(false)
        }
    }

    const getSpecialOfferPlan = () => {
        try {
            setIsLoading(true)
            wtApiCall(
                'plans?service_type=5',
                {},
                'get',
                (response) => {
                    if (response && response.data) {
                        setSpecialOffer(response.data);
                    }
                    setIsLoading(false)
                },
                (error) => {
                    showErrorNotification(error.message)
                    setIsLoading(false)
                },
                true
            )
        } catch (error) {
            showErrorNotification('Error processing!', error);
            setIsLoading(false)
        }
    }

    const getExpertConsultationOfferPlan = () => {
        try {
            setIsLoading(true)
            wtApiCall(
                'plans?service_type=6',
                {},
                'get',
                (response) => {
                    if (response && response.data) {
                        setExpertConsultationOffer(response.data);
                    }
                    setIsLoading(false)
                },
                (error) => {
                    showErrorNotification(error.message)
                    setIsLoading(false)
                },
                true
            )
        } catch (error) {
            showErrorNotification('Error processing!', error);
            setIsLoading(false)
        }
    }

    const getCountries = () => {
        try {
            setIsLoading(true)
            wtApiCall(
                'countries',
                {},
                'get',
                (response) => {
                    if (response && response.data) {
                        setCountries(response.data);

                        const defaultCountry = response.data.find(country => country.default === 1);
                        if (defaultCountry) {
                            setSelectedCountry(defaultCountry);
                        }
                    }
                    setIsLoading(false)
                },
                (error) => {
                    showErrorNotification(error.message)
                    setIsLoading(false)
                },
                true
            )
        } catch (error) {
            showErrorNotification('Error processing!', error);
            setIsLoading(false)
        }
    }


    const getMyCopy = (data) => {

        if (+quiz.level === 109) {
            handleSubmit(1, selectedPlan);
        }
        if (+quiz.level === 110) {
            handleSubmit(2, [specialOffer[0].id]);
        }

        if (+quiz.level === 111) {
            handleSubmit(3, [expertConsultationOffer[0].id]);
        }

        if (+quiz.level === 112) {
            handleSubmit(4, [], data);
        }
    }

    const handleSubmit = (type, data, email = '') => {

        setIsLoading(true)
        try {
            //call payment API
            wtApiCall(
                'payment/createPayment',
                {
                    type: type,
                    data: data,
                    email: email,
                    country: selectedCountry
                },
                'post',
                (response) => {
                    if (response && response.data) {
                        showSuccessNotification("Success");
                        setIsLoading(false)

                        if (+quiz.level === 109 || +quiz.level === 110) {
                            const newQuiz = { ...quiz, level: 111 };
                            wtSaveToLocalStorage('quiz', newQuiz);
                            navigate('/Upsell')
                        }
                        else if (+quiz.level === 111) {
                            const newQuiz = { ...quiz, level: 112 };
                            wtSaveToLocalStorage('quiz', newQuiz);
                            navigate('/Upsell')
                        }
                        else {
                            wtSaveToLocalStorage('authUser', response.data.user)
                            wtSaveToLocalStorage('authToken', response.data.token)
                            wtSaveToLocalStorage('idle_timeout', currentDateTime());
                            wtSaveToLocalStorage('configurations', response.data.configurations)
                            navigate('/Introduction')
                        }
                    }
                },
                (error) => {
                    showErrorNotification(`Error submitting payment:${error.message}`);
                    setIsLoading(false)
                },
                true
            )
        } catch (error) {
            showErrorNotification(`Error submitting payment:${error.message}`);
            setIsLoading(false)
        }
    };

    const noThankyou = () => {
        if (+quiz.level === 109) {
            const newQuiz = { ...quiz, level: 110 };
            wtSaveToLocalStorage('quiz', newQuiz);
            navigate('/Upsell')
        }
        if (+quiz.level === 110) {
            const newQuiz = { ...quiz, level: 111 };
            wtSaveToLocalStorage('quiz', newQuiz);
            setStep(2)
            navigate('/Upsell')
        }

        if (+quiz.level === 111) {
            const newQuiz = { ...quiz, level: 112 };
            wtSaveToLocalStorage('quiz', newQuiz);
            setStep(3)
            navigate('/Upsell')
        }
    }

    //if loading true then return spinner
    if (isLoading) {
        return <Loading />;
    }

    console.log(quiz)

    return (
        <React.Fragment>
            {/*  check if quiz is not null and token is not empty */}
            {(authTokenWebsite !== '' && quiz !== null) &&
                <div className='container' style={{ height: "100vh" }}>
                    <div className='quiz' style={{
                        background:
                            (quiz.level === 110) ?
                                "linear-gradient(360deg, #ff5e78ff 15%, #ab32b4ff 40%, #811cd2ff 60%, #6c11e1ff 80%, #5606efff 100%)"
                                :
                                "#fff"
                    }}>
                        <div className="quiz-header"
                            style={{
                                borderBottom: (quiz.level === 110) ? 0 : "1px solid rgb(248, 244, 244)",
                                boxShadow: "rgb(215, 215, 215)",
                                zIndex: 1000,
                                backgroundColor: (quiz.level === 110) ? "transparent"
                                    : "#ffff",
                                position: (quiz.level === 110) ? "unset" : "sticky",
                                marginBottom: 40
                            }}
                        >
                            <Row justify="space-between" align="middle" className="header-row">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <img
                                        height={32}
                                        src={(quiz.level !== 110)
                                            ? `${AppConstants.front_base_url}/images/astroguide-logo-darkgrey-desktop.png`
                                            : `${AppConstants.front_base_url}/images/astroguide-logo-white-mobile.png`
                                        }
                                        alt="Logo"
                                        className="logo"
                                    />
                                </Col>
                                <Col xs={4} md={4} className="placeholder-column" />
                            </Row>
                        </div>

                        <div className='quiz-body'>
                            {quiz.level !== 110 &&
                                <Row justify="center">
                                    <Col xs={0} sm={0} md={8} lg={8} xl={8}></Col>
                                    <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                        <Steps
                                            size="small"
                                            className='upsell-steps'
                                            current={step}
                                            direction="horizontal" /* Force horizontal layout */
                                            labelPlacement="vertical" /* Ensure text is below icons */
                                            items={items}
                                            responsive={false}
                                        />
                                    </Col>
                                    <Col xs={0} sm={0} md={8} lg={8} xl={8}></Col>
                                </Row>
                            }

                            <Row justify="center">
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                                    {(+quiz.level === 109) &&
                                        <Row>
                                            <Col xs={0} sm={0} md={5} lg={6} xl={7}></Col>
                                            <Col xs={24} sm={22} md={14} lg={12} xl={10} style={{ textAlign: "center" }}>
                                                <Level109Quiz
                                                    quiz={quiz}
                                                    plans={plans}
                                                    currency={selectedCountry?.currency.currency_symbol}
                                                    selectedPlan={selectedPlan}
                                                    setSelectedPlan={setSelectedPlan}
                                                    noThankyou={() => noThankyou()}
                                                    getMyCopy={() => getMyCopy()}

                                                />
                                            </Col>
                                            <Col xs={0} sm={0} md={5} lg={6} xl={7}></Col>
                                        </Row>
                                    }

                                    {(+quiz.level === 110) &&
                                        <Row>
                                            <Col xs={0} sm={0} md={5} lg={6} xl={7}></Col>
                                            <Col xs={24} sm={22} md={14} lg={12} xl={10} style={{ textAlign: "center" }}>
                                                <Level110Quiz
                                                    quiz={quiz}
                                                    specialOffer={specialOffer}
                                                    currency={selectedCountry?.currency.currency_symbol}
                                                    noThankyou={() => noThankyou()}
                                                    getMyCopy={() => getMyCopy()}

                                                />
                                            </Col>
                                            <Col xs={0} sm={0} md={5} lg={6} xl={7}></Col>
                                        </Row>
                                    }


                                    {(+quiz.level === 111) &&
                                        <Row>
                                            <Col xs={0} sm={0} md={5} lg={6} xl={7}></Col>
                                            <Col xs={24} sm={22} md={14} lg={12} xl={10} style={{ textAlign: "center" }}>
                                                <Level111Quiz
                                                    quiz={quiz}
                                                    expertConsultationOffer={expertConsultationOffer}
                                                    currency={selectedCountry?.currency.currency_symbol}
                                                    noThankyou={() => noThankyou()}
                                                    getMyCopy={() => getMyCopy()}

                                                />
                                            </Col>
                                            <Col xs={0} sm={0} md={5} lg={6} xl={7}></Col>
                                        </Row>
                                    }

                                    {(+quiz.level === 112) &&
                                        <Row>
                                            <Col xs={0} sm={0} md={5} lg={6} xl={7}></Col>
                                            <Col xs={24} sm={22} md={14} lg={12} xl={10} style={{ textAlign: "center" }}>
                                                <Level112Quiz
                                                    quiz={quiz}
                                                    expertConsultationOffer={expertConsultationOffer}
                                                    currency={selectedCountry?.currency.currency_symbol}
                                                    noThankyou={() => noThankyou()}
                                                    getMyCopy={(email) => getMyCopy(email)}

                                                />
                                            </Col>
                                            <Col xs={0} sm={0} md={5} lg={6} xl={7}></Col>
                                        </Row>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </div>


                    {/* Footer */}
                    {quiz.level !== 110 &&
                        <Footer className='checkout-footer'>
                            Copyright © {new Date().getFullYear()} Astro Guide™. All rights reserved.
                            Contact    Terms of Use    Privacy Policy
                        </Footer>
                    }
                </div>
            }
        </React.Fragment >
    );
}

export default Upsell;
