import { notification } from 'antd';

const openNotification = (type, error, message) => {
    notification[type]({
        message: error,
        description: message,
        duration: 3, // Duration in seconds
    });
};

export const showSuccessNotification = (message) => {
    openNotification('success', 'Success', message);
};

export const showErrorNotification = (message) => {
    openNotification('error', 'Error', message);
};