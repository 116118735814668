import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../helpers/commons/loader';
import { wtGetFromLocalStorage } from '../../../../helpers/commons/LocalStorage';
import { useNavigate } from 'react-router-dom';
import { wtLogOut } from '../../../../helpers/commons/utils';
import { AppConstants } from '../../../../helpers/commons/constants';

const { Text } = Typography;

const Introduction = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const authToken = wtGetFromLocalStorage('authToken', '');
    const authUser = wtGetFromLocalStorage('authUser', null);

    useEffect(() => {
        if (authToken === '' || authUser === null) {
            wtLogOut();
        }
    }, [authToken, authUser]);

    const [isLoading, setIsLoading] = useState(false);


    //if loading true then return spinner
    if (isLoading) {
        return <Loading />;
    }

    return (
        <React.Fragment>
            {/*  check if quiz is not null and token is not empty */}
            {(authToken !== '' && authUser !== null) &&
                <div className='container' >
                    <Row justify="center">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Row style={{ marginBottom: 40 }}>
                                <Col xs={0} sm={0} md={24} lg={24} xl={24} style={{ textAlign: "left" }}>
                                    <img
                                        style={{ width: '100%', height: "auto" }}
                                        src={`${AppConstants.front_base_url}/images/app-1-adv-banner.png`}
                                        alt="Adv Banner"
                                    />
                                </Col>
                            </Row>

                            <Row style={{ marginBottom: 40 }}>
                                <Col xs={1} sm={1} md={6} lg={6} xl={6}></Col>
                                <Col xs={22} sm={22} md={12} lg={12} xl={12} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 24, fontWeight: "bold" }}>
                                        Introduction
                                    </Text>
                                </Col>
                                <Col xs={1} sm={1} md={6} lg={6} xl={6}></Col>
                            </Row>

                            <Row style={{ marginBottom: 30 }}>
                                <Col xs={1} sm={1} md={6} lg={6} xl={6}></Col>
                                <Col xs={22} sm={22} md={12} lg={12} xl={12} style={{ textAlign: "left" }}>
                                    <Text style={{ fontSize: 14 }}>
                                        Thank you for choosing Astro Guide™, your trusted source for astrology and horoscopes.
                                    </Text>
                                </Col>
                                <Col xs={1} sm={1} md={6} lg={6} xl={6}></Col>
                            </Row>

                            <Row style={{ marginBottom: 30 }}>
                                <Col xs={1} sm={1} md={6} lg={6} xl={6}></Col>
                                <Col xs={22} sm={22} md={12} lg={12} xl={12} style={{ textAlign: "left" }}>
                                    <Text style={{ fontSize: 14 }}>
                                        The following Astro Guide™ analysis was customized based on your birth details. The positions of celestial bodies at the time of your birth reveal great insights and useful hints to help you discover yourself and guide you towards happiness.
                                    </Text>
                                </Col>
                                <Col xs={1} sm={1} md={6} lg={6} xl={6}></Col>
                            </Row>

                            <Row style={{ marginBottom: 30 }}>
                                <Col xs={1} sm={1} md={6} lg={6} xl={6}></Col>
                                <Col xs={22} sm={22} md={12} lg={12} xl={12} style={{ textAlign: "left" }}>
                                    <Text style={{ fontSize: 14 }}>
                                        Your path and ours may have collided because you were seeking answers, you’re feeling lost, or you were simply curious about what the stars have in store for you. Astro Guide™ was developed to help individuals like you develop a deeper understanding of themselves for personal growth, happiness, and to build stronger relationships in life.
                                    </Text>
                                </Col>
                                <Col xs={1} sm={1} md={6} lg={6} xl={6}></Col>
                            </Row>

                            <Row style={{ marginBottom: 30 }}>
                                <Col xs={1} sm={1} md={6} lg={6} xl={6}></Col>
                                <Col xs={22} sm={22} md={12} lg={12} xl={12} style={{ textAlign: "left" }}>
                                    <Text style={{ fontSize: 14 }}>
                                        Astro Guide™ was created with the help of experts, bringing together the best of astrological knowledge with psychological insights. For the answers, affirmations, encouragement or guidance you seek in your personal life or relationships, Astro Guide™ demystifies the language of the stars in  layman terms and 	understanding, to help you choose the best path you deserve in life, love and the future.
                                    </Text>
                                </Col>
                                <Col xs={1} sm={1} md={6} lg={6} xl={6}></Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 30 }}>
                                <Col xs={1} sm={1} md={14} lg={14} xl={14}></Col>
                                <Col xs={22} sm={22} md={8} lg={8} xl={8} style={{ textAlign: "center" }}>
                                    <Button type="primary" size="large" className="cta-button"
                                        style={{ maxWidth: 200 }}
                                    // onClick={() => checkoutClick()}
                                    >
                                        Consult an Advisor
                                    </Button>   
                                </Col>
                                <Col xs={1} sm={1} md={2} lg={2} xl={2}></Col>
                            </Row>

                        </Col>
                    </Row>
                </div>
            }
        </React.Fragment >
    );
}

export default Introduction;
