import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { wtGetFromLocalStorage, wtSaveToLocalStorage } from '../../../helpers/commons/LocalStorage';
import { useNavigate } from 'react-router-dom';
import '../StartQuiz/Quiz.css';

const { Title, Text } = Typography;

const Level112Quiz = ({ quiz, getMyCopy }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const authTokenWebsite = wtGetFromLocalStorage('authTokenWebsite', '');

    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorMessageConfirmEmail, setErrorMessageConfirmEmail] = useState('');


    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    useEffect(() => {
        if (authTokenWebsite === '' || quiz === null) {
            navigate('/');
        }
        else {
            if (+quiz.level !== 112) {
                navigate('/');
            }
        }
    }, [authTokenWebsite, quiz]);

    const handleChange = (key, value) => {

        if (key === 'email') {
            setEmail(value);
        }
        if (key === 'confirm_email') {
            setConfirmEmail(value);
        }

        // Reset validation on input change
        if (!emailRegex.test(value)) {
            setIsValid(true);
            setErrorMessage('');
            setErrorMessageConfirmEmail('');
        }
    };

    const checkEmail = () => {
        if (email === '') {
            setIsValid(false);
            setErrorMessage(t('quiz-closing_6-qn_email_both_part-3'));
            return;
        }

        if (confirmEmail === '') {
            setIsValid(false);
            setErrorMessageConfirmEmail(t('upsell_5-consult_body-5'));
            return;
        }

        // Validate email
        if (!emailRegex.test(email)) {
            setIsValid(false);
            setErrorMessage(t('upsell_5-consult_body-7'));
            return;

        }
        else if (!emailRegex.test(confirmEmail)) {
            setIsValid(false);
            setErrorMessageConfirmEmail(t('upsell_5-consult_body-7'));
            return;

        }
        else {
            if (email.trim() !== confirmEmail.trim()) {
                setIsValid(false);
                setErrorMessageConfirmEmail(t('upsell_5-consult_body-6'));
                return;
            }

            setIsValid(true);
            setErrorMessage('');
        }

        getMyCopy(email);
    }

    return (
        <React.Fragment>
            {/*  check if quiz is not null and token is not empty */}
            {(authTokenWebsite !== '' && quiz !== null && +quiz.level === 112) &&
                <Row justify="center">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="responsive-div">
                        <Row justify="center" style={{ marginBottom: 30, fontSize: 14 }}>
                            <Col xs={4} sm={4} md={8} lg={8} xl={8}></Col>
                            <Col xs={16} sm={16} md={8} lg={8} xl={8} style={{ textAlign: "center" }}>
                                <Text style={{ fontSize: 30, fontWeight: "500" }}>
                                    {t('upsell_1-sections_title-3')}
                                </Text>
                            </Col>
                            <Col xs={4} sm={4} md={8} lg={8} xl={8}></Col>
                        </Row>

                        <Row justify="center" style={{ marginBottom: 60, fontSize: 14 }}>
                            <Col xs={1} sm={1} md={4} lg={4} xl={4}></Col>
                            <Col xs={22} sm={22} md={16} lg={16} xl={16} style={{ textAlign: "center" }}>
                                <Text style={{ fontSize: 18, fontWeight: "500" }}>
                                    {t('upsell_5-consult_body-5')}
                                </Text>
                            </Col>
                            <Col xs={1} sm={1} md={4} lg={4} xl={4}></Col>
                        </Row>


                        <Row justify="center" style={{ fontSize: 14, marginBottom: 5 }}>
                            <Col xs={1} sm={1} md={4} lg={4} xl={4}></Col>
                            <Col xs={22} sm={22} md={16} lg={16} xl={16} style={{ textAlign: "left" }}>
                                <Text style={{ fontSize: 18, fontWeight: "500" }}>
                                    {t('quiz-closing_6-qn_email_both_part-2') + ":"}
                                    <span style={{ color: "red" }}>*</span>
                                </Text>
                            </Col>
                            <Col xs={1} sm={1} md={4} lg={4} xl={4}></Col>
                        </Row>


                        <Row justify="center" style={{ marginBottom: 40 }}>
                            <Col xs={24} sm={24} md={18} lg={18} xl={18} style={{ textAlign: "center" }}>
                                <div style={{ position: 'relative', display: 'inline-block', width: "100%" }}>
                                    <input
                                        className='email-input'
                                        value={email}
                                        onChange={(e) => handleChange('email', e.target.value)}
                                        placeholder={t('quiz-closing_6-qn_email_both_part-2')}
                                        style={{
                                            borderColor: isValid ? 'black' : 'red', // Default border color and error color
                                            borderWidth: '2px',
                                            borderStyle: 'solid',
                                            outline: 'none',
                                            width: "100%"
                                        }}
                                    />
                                    {/* Error message */}
                                    {!isValid && (
                                        <div className='email-validation'>
                                            {errorMessage}
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>


                        <Row justify="center" style={{ fontSize: 14, marginBottom: 5 }}>
                            <Col xs={1} sm={1} md={4} lg={4} xl={4}></Col>
                            <Col xs={22} sm={22} md={16} lg={16} xl={16} style={{ textAlign: "left" }}>
                                <Text style={{ fontSize: 18, fontWeight: "500" }}>
                                    {t('payment_1-pay_subtitle-2') + ":"}
                                    <span style={{ color: "red" }}>*</span>
                                </Text>
                            </Col>
                            <Col xs={1} sm={1} md={4} lg={4} xl={4}></Col>
                        </Row>


                        <Row justify="center" style={{ marginBottom: 40 }}>
                            <Col xs={24} sm={24} md={18} lg={18} xl={18} style={{ textAlign: "center" }}>
                                <div style={{ position: 'relative', display: 'inline-block', width: "100%" }}>
                                    <input
                                        className='email-input'
                                        value={confirmEmail}
                                        onChange={(e) => handleChange('confirm_email', e.target.value)}
                                        placeholder={t('payment_1-pay_subtitle-2')}
                                        style={{
                                            borderColor: isValid ? 'black' : 'red', // Default border color and error color
                                            borderWidth: '2px',
                                            borderStyle: 'solid',
                                            outline: 'none',
                                            width: "100%"
                                        }}
                                    />
                                    {/* Error message */}
                                    {!isValid && (
                                        <div className='email-validation'>
                                            {errorMessageConfirmEmail}
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>


                        <Row justify="center" style={{ marginBottom: 20 }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                <Button type="primary" size="large" className="cta-button"
                                    style={{ maxWidth: 300 }}
                                    onClick={() => checkEmail()}
                                >
                                    {t('button_continue')}
                                </Button>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            }
        </React.Fragment >
    );
}

export default Level112Quiz;
