/**
 * Settings for Axios Http Api calls.
 * Create a copy of this file with name Request.js and enter config there.
 * Request.js file is not tracked in .git
 */

// Base url of backend app
export const APP_BASE_URL = process.env.REACT_APP_APP_BASE_URL || "http://astro-guide/";

// Base url of backend api
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://astro-guide/admin/";

// Base url of frontend app
export const FRONT_BASE_URL = process.env.REACT_APP_FRONT_BASE_URL || "http://localhost:3000/";

// url of map suggestions
export const MAP_SUGGESTIONS_URL = process.env.REACT_APP_MAP_SUGGESTIONS_URL || "https://nominatim.openstreetmap.org/search?format=json&q=";

// url of map
export const MAP_URL = process.env.REACT_APP_MAP_URL || "https://{s}.tiles.cyclosm.org/cyclosm/{z}/{x}/{y}.png";

// url of map for getting place name
export const MAP_URL_PLACE_NAME = process.env.REACT_APP_MAP_URL_PLACE_NAME || "https://nominatim.openstreetmap.org/reverse?format=json";

// url of map for getting place name
export const STRIPE_PUB_KEY = process.env.REACT_APP_STRIPE_PUB_KEY;
