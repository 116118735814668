import moment from 'moment';

export const wtGetDateOnly = (date) => {
  if (!date) {
    return '';
  }

  let d = date;
  d = d.split(' ');

  return d[0];
};

export const wtFormatDate = (date, format) => {
  if (date === "" || date === null) {
    return "-";
  }
  return moment(date).format(format ? format : 'DD-MM-YYYY');
};

export const wtAddDaysToDate = (date, days, format) => {
  return moment(date).add(days, "days").format(format ? format : 'DD-MM-YYYY');
};

export const wtSubDaysToDate = (date, days, format) => {
  return moment(date).subtract(days, "days").format(format ? format : 'DD-MM-YYYY');
};

export const wtGetMonthsFrom2Dates = (startDate, endDate, precise) => {
  return moment(endDate).diff(moment(startDate), "months", !!precise);
};

export const wtGetHoursFrom2Dates = (startDate, endDate, precise) => {
  return moment(endDate).diff(moment(startDate), "hours", !!precise);
};

export const wtGetSecondsFrom2Dates = (startDate, endDate, precise) => {
  return moment(endDate).diff(moment(startDate), "seconds", !!precise);
};

export const getFutureDate = (date, amount, unit, format = false) => {
  return moment(date).add(amount, unit).format(format ? format : 'DD-MM-YYYY');
}

export const currentStartOfMonthDate = () => {
  return moment().startOf('month').format('YYYY-MM-DD');
};

export const currentStartOfYearDate = () => {
  return moment().startOf('year').startOf('month').format('YYYY-MM-DD');
};

export const currentEndOfYearDate = () => {
  return moment().endOf('year').format('YYYY-MM-DD');
};

export const currentDate = (format) => {
  return moment().format(format || 'YYYY-MM-DD');
};

export const currentDateTime = (format) => {
  return moment().format(format || 'YYYY-MM-DD HH:mm:ss');
};

export const currentMonth = () => {
  return moment().format('MM');
};

export const wtGetMonthFromDate = (date) => {
  return moment(date).format('MM');
};
export const wtGetYearFromDate = (date) => {
  return moment(date).format('YYYY');
};

export const currentFullYear = () => {
  return moment().format('YYYY');
};

export const lastsFullYear = () => {
  return moment().subtract(1, 'years').format('YYYY');
};

export const dateLast30DaysBefore = () => {
  let dateMinus30 = new moment().subtract(30, 'days');
  return dateMinus30;
}

export const lastMonthFirstDate = () => {
  let prevMonthFirstDay = new moment().subtract(1, 'months').date(1);
  return prevMonthFirstDay;
}

export const lastMonthLastDate = () => {
  let prevMonthLastDay = new moment().subtract(1, 'months').date(0);
  return prevMonthLastDay;
}

export const wtDaysOfMonth = (month, year) => {
  return moment((year || '2000') + '-' + month, 'YYYY-MM').endOf('month').format('D');
};

export const getDaysInMonth = (year, month) => {
  return new Date(year, month, 0).getDate();
};

export const getMonthNumber = (monthName) => {
  // Create a moment object with the month name and the current year
  const month = moment(monthName, 'MMMM');
  // Get the month number (0-based index, so January is 0)
  return month.month();
};

export const wtDaysOfMonthYear = (monthYear) => {
  return moment(monthYear, 'YYYY-MM').endOf('month').format('D');
};

export const wtmydateformat = (d) => {
  let date = new Date(d);
  if (isNaN(date.getTime())) {
    return d;
  }
  else {
    let month = [];
    month[0] = "January";
    month[1] = "Feburary";
    month[2] = "March";
    month[3] = "April";
    month[4] = "May";
    month[5] = "June";
    month[6] = "July";
    month[7] = "August";
    month[8] = "September";
    month[9] = "October";
    month[10] = "November";
    month[11] = "December";

    let day = date.getDate();

    if (day < 10) {
      day = "0" + day;
    }
    return month[date.getMonth()] + " " + day + ", " + date.getFullYear();
  }
}

export const wtgetmonthnameformat = (d) => {
  let date = new Date(d);
  if (isNaN(date.getTime())) {
    return d;
  }
  else {
    let month = [];
    month[0] = "January";
    month[1] = "Feburary";
    month[2] = "March";
    month[3] = "April";
    month[4] = "May";
    month[5] = "June";
    month[6] = "July";
    month[7] = "August";
    month[8] = "September";
    month[9] = "October";
    month[10] = "November";
    month[11] = "December";

    let day = date.getDate();

    if (day < 10) {
      day = "0" + day;
    }
    return month[date.getMonth()] + "-" + date.getFullYear();
  }
}

export const wtgetnextmonthnameformat = (mon, year) => {

  if (mon < 10) {
    mon = "0" + mon;
  }
  return year + "-" + mon;
}

export const wtgetmonthname = (d) => {

  let date = new Date(d);
  if (isNaN(date.getTime())) {
    return d;
  }
  else {
    let month = [];
    month[0] = "January";
    month[1] = "Feburary";
    month[2] = "March";
    month[3] = "April";
    month[4] = "May";
    month[5] = "June";
    month[6] = "July";
    month[7] = "August";
    month[8] = "September";
    month[9] = "October";
    month[10] = "November";
    month[11] = "December";

    let day = date.getDate();

    if (day < 10) {
      day = "0" + day;
    }
    // TODO: Check why this check is applied here
    return month[date.getMonth()];
  }
}

export const wtGetDateMonthYearFormat = (date) => {
  if (date !== '' && date !== null) {
    return moment(date).format('DD-MM-YYYY');
  }
  else {
    return ''
  }
}
export const wtGetMonthNameDateYearFormat = (date) => {
  if (date !== '' && date !== null) {
    return moment(date).format('MMMM DD, YYYY');
  }
  else {
    return ''
  }
}

export const wtGetMonthNameYearFormat = (date) => {
  if (date !== '' && date !== null) {
    return moment(date).format('MMMM, YYYY');
  }
  else {
    return ''
  }
}

export const wtGetDateToday = () => {
  return moment().format('YYYY-MM-DD')
}

export const wtGetTimeNow = () => {
  return moment().format('HH:mm')
}

export const wtGetDateTimeFormat = (date) => {
  if (date !== '' && date !== null) {
    return moment(date).format('DD-MM-YYYY HH:mm A');
  }
  else {
    return ''
  }
}

export const wtConvertHourValue = (hours) => {
  if (hours !== '' && hours !== null) {
    let convertedTime = '';
    let hoursConverted = Math.floor(hours)
    let minutesConverted = Math.round((hours - Math.floor(hours)) * 60)
    if (hoursConverted > 0) {
      convertedTime += `${hoursConverted} Hours`
    }
    if (minutesConverted > 0) {
      convertedTime += ` ${minutesConverted} Minutes`
    }

    return convertedTime
  }
  else {
    return ''
  }
}