import React, { useEffect } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { wtGetFromLocalStorage } from '../../../helpers/commons/LocalStorage';
import { useNavigate } from 'react-router-dom';
import '../StartQuiz/Quiz.css';

const { Title, Text } = Typography;

const Level111Quiz = ({ quiz, currency, expertConsultationOffer, getMyCopy, noThankyou }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const authTokenWebsite = wtGetFromLocalStorage('authTokenWebsite', '');


    useEffect(() => {
        if (authTokenWebsite === '' || quiz === null) {
            navigate('/');
        }
        else {
            if (+quiz.level !== 111) {
                navigate('/');
            }
        }
    }, [authTokenWebsite, quiz]);

    return (
        <React.Fragment>
            {/*  check if quiz is not null and token is not empty */}
            {(authTokenWebsite !== '' && quiz !== null && +quiz.level === 111 && expertConsultationOffer.length > 0) &&
                <Row justify="center">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="responsive-div">
                        <Row justify="center" style={{ marginBottom: 60, fontSize: 14 }}>
                            <Col xs={4} sm={4} md={8} lg={8} xl={8}></Col>
                            <Col xs={16} sm={16} md={8} lg={8} xl={8} style={{ textAlign: "center" }}>
                                <Text style={{ fontSize: 30, fontWeight: "500" }}>
                                    {t('upsell_5-consult_title')}
                                </Text>
                            </Col>
                            <Col xs={4} sm={4} md={8} lg={8} xl={8}></Col>
                        </Row>

                        <Row justify="center" style={{ marginBottom: 20, fontSize: 14 }}>
                            <Col xs={0} sm={0} md={2} lg={2} xl={2}></Col>
                            <Col xs={24} sm={24} md={20} lg={20} xl={20} style={{ textAlign: "center" }}>
                                <Text style={{ fontSize: 18, fontWeight: "500" }}>
                                    {t('upsell_5-consult_subtitle')}
                                </Text>
                            </Col>
                            <Col xs={0} sm={0} md={2} lg={2} xl={2}></Col>
                        </Row>

                        <Row justify="center" style={{ marginBottom: 60, fontSize: 14 }}>
                            <Col xs={2} sm={2} md={5} lg={5} xl={5}></Col>
                            <Col xs={20} sm={20} md={14} lg={14} xl={14} style={{ textAlign: "left" }}>
                                <Text style={{ fontSize: 12, fontWeight: "400" }}>
                                    {t('upsell_5-consult_body-1')}
                                </Text>
                            </Col>
                            <Col xs={2} sm={2} md={5} lg={5} xl={5}></Col>
                        </Row>



                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                <Row justify="center" style={{ marginBottom: 20 }}>
                                    <Col xs={1} sm={1} md={4} lg={4} xl={4} ></Col>

                                    <Col xs={22} sm={22} md={16} lg={16} xl={16} style={{ textAlign: "center" }}>
                                        <div className="special-offer-thumbnail-container">
                                            <div className="special-offer-thumbnail-content">
                                                <Row justify="center" style={{ marginBottom: 20 }}>
                                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                        <Text style={{ fontSize: 20, fontWeight: "bold" }}>
                                                            {t('checkout_5-offerbox_title') + " "}
                                                            {currency + Math.round(expertConsultationOffer[0].discount)}
                                                        </Text>
                                                    </Col>
                                                </Row>

                                                <Row justify="center">
                                                    <Col xs={20} sm={20} md={20} lg={20} xl={20} style={{ textAlign: "left" }}>
                                                        <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                            {t('upsell_5-consult_body-2')}
                                                        </Text>
                                                    </Col>
                                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} style={{ textAlign: "right" }}>
                                                        <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                            {currency + Math.round(expertConsultationOffer[0].discount)}
                                                        </Text>
                                                    </Col>
                                                </Row>

                                                <hr style={{ width: "100%" }} />

                                                <Row justify="center">
                                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ textAlign: "left" }}>
                                                        <Text style={{ fontSize: 13, fontWeight: "500" }}>
                                                            {t('upsell_5-consult_body-3')}
                                                        </Text>
                                                    </Col>

                                                    <Col xs={8} sm={8} md={8} lg={8} xl={8} style={{ textAlign: "right" }}>
                                                        <Text className='upsell-colored-title'>
                                                            {t('upsell_5-consult_body-3-1') + " " + Math.round(expertConsultationOffer[0].discount_rate) + "%"}
                                                        </Text>
                                                    </Col>

                                                    <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{ textAlign: "right" }}>
                                                        <Text style={{ fontSize: 13, fontWeight: "500" }}>

                                                            {currency + Math.round(expertConsultationOffer[0].price)}
                                                        </Text>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={1} sm={1} md={4} lg={4} xl={4} ></Col>
                                </Row>


                            </Col>
                        </Row>


                        <Row justify="center" style={{ marginBottom: 20 }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                <Button type="primary" size="large" className="cta-button"
                                    style={{ maxWidth: 300 }}
                                    onClick={() => getMyCopy()}
                                >
                                    {t('upsell_5-consult_action')}
                                </Button>
                            </Col>
                        </Row>

                        <Row justify="center" style={{ marginBottom: 20, fontSize: 14 }}>
                            <Col xs={0} sm={0} md={4} lg={4} xl={4}></Col>
                            <Col xs={24} sm={24} md={16} lg={16} xl={16} style={{ textAlign: "center" }}>
                                <Text style={{ fontSize: 10, fontWeight: "500" }}>
                                    {t('upsell_5-consult_body-4')}
                                </Text>
                            </Col>
                            <Col xs={0} sm={0} md={4} lg={4} xl={4}></Col>
                        </Row>


                        <Row justify="center" style={{ marginBottom: 40, fontSize: 14, cursor: "pointer" }}
                            onClick={() => noThankyou()}
                        >
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Text style={{ fontSize: 10, textDecoration: "underline", fontWeight: "500" }}
                                    onClick={() => noThankyou()}
                                >
                                    {t('upsell_3-signup_skip')}
                                </Text>
                            </Col>
                        </Row>


                    </Col>
                </Row>
            }
        </React.Fragment >
    );
}

export default Level111Quiz;
