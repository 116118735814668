import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { wtGetFromLocalStorage } from '../../../helpers/commons/LocalStorage';
import { useNavigate } from 'react-router-dom';
import '../StartQuiz/Quiz.css';
import { CheckOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const Level109Quiz = ({ quiz, plans, currency, selectedPlan, setSelectedPlan, getMyCopy, noThankyou }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const authTokenWebsite = wtGetFromLocalStorage('authTokenWebsite', '');


    useEffect(() => {
        if (authTokenWebsite === '' || quiz === null) {
            navigate('/');
        }
        else {
            if (+quiz.level !== 109) {
                navigate('/');
            }
        }
    }, [authTokenWebsite, quiz]);




    const level109Answer = (planId) => {
        setSelectedPlan((prevPlans) => {
            if (prevPlans.includes(planId)) {
                // Check if there is only one plan left in the selected array
                if (prevPlans.length === 1) {
                    // If it's the last option, prevent deselecting it
                    return prevPlans;
                } else {
                    // Otherwise, remove the selected plan
                    return prevPlans.filter(id => id !== planId);
                }
            } else {
                // If the plan is not selected, add it to the array
                return [...prevPlans, planId];
            }
        });
    };

    return (
        <React.Fragment>
            {/*  check if quiz is not null and token is not empty */}
            {(authTokenWebsite !== '' && quiz !== null && +quiz.level === 109 && plans.length > 0) &&
                <Row justify="center">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className="responsive-div">

                        {/* For Both single */}
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                            <Row justify="center" style={{ marginBottom: 60 }}>
                                <Col xs={1} sm={1} md={3} lg={3} xl={3}></Col>
                                <Col xs={22} sm={22} md={18} lg={18} xl={18} style={{ backgroundColor: "#F5F5F7", borderRadius: 20, padding: 20 }}>

                                    <Row justify="center" style={{ fontSize: 14 }}>
                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <Text style={{ fontSize: 28 }}>
                                                {t('quiz-closing_4-qn_spontaneous_both_emoji')}
                                            </Text>
                                        </Col>
                                        <Col xs={18} sm={18} md={18} lg={18} xl={18}>
                                            <Row justify="center" style={{ fontSize: 14 }}>
                                                <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                                                <Col xs={20} sm={20} md={20} lg={20} xl={20} style={{ textAlign: "center", fontWeight: "bold" }}>
                                                    <Text>
                                                        {t('upsell_2-confirmation_title')}
                                                    </Text>
                                                </Col>
                                                <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                                            </Row>

                                            <Row justify="center">
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                                    <Text style={{ fontSize: 12 }}>
                                                        {t('upsell_2-confirmation_subtitle')}
                                                    </Text>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                                            <Text style={{ fontSize: 28 }}>
                                                {t('quiz-closing_4-qn_spontaneous_both_emoji')}
                                            </Text>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={1} sm={1} md={3} lg={3} xl={3}></Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 10, fontSize: 14 }}>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                                <Col xs={20} sm={20} md={20} lg={20} xl={20} style={{ textAlign: "center" }}>
                                    <Text style={{ fontSize: 30, fontWeight: "500" }}>
                                        {t('upsell_1-sections_title-1')}
                                    </Text>
                                </Col>
                                <Col xs={2} sm={2} md={2} lg={2} xl={2}></Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 10, fontSize: 14 }}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Text>
                                        {t('upsell_3-signup_subtitle')}
                                    </Text>
                                </Col>
                            </Row>


                            {plans.map((plan) => (
                                <Row justify="center" style={{ marginBottom: 10 }}
                                    onClick={() => level109Answer(plan.id)}
                                    key={"plan-" + plan.id}
                                >
                                    <Col xs={0} sm={0} md={2} lg={2} xl={2}></Col>
                                    <Col xs={24} sm={24} md={20} lg={20} xl={20} style={{
                                        border: "1px solid #cccccc", borderRadius: 15, height: 65,
                                        backgroundColor: (selectedPlan.includes(plan.id)) ?
                                            "#cccccc" : ""
                                    }} className='hover-items'>
                                        <Row>
                                            <Col xs={3} sm={3} md={2} lg={2} xl={2} style={{ display: 'flex', alignItems: 'center' }}>
                                                <span style={{ marginLeft: 15, fontSize: 24 }}>
                                                    {t(plan.icon)}
                                                </span>
                                            </Col>
                                            <Col xs={17} sm={17} md={18} lg={18} xl={18}>
                                                <Title level={5} className='quiz-5-title-options'>
                                                    <Row>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            {t(plan.translation_key)}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                                            <Text style={{ fontWeight: "normal", fontSize: 12 }}>
                                                                {currency + (Math.round(plan.discount)) + " (" + t(plan.translation_subtitle_key) + " " + currency + t(Math.round(plan.price) + ")")}
                                                            </Text>
                                                        </Col>
                                                    </Row>
                                                </Title>
                                            </Col>
                                            <Col xs={4} sm={4} md={4} lg={4} xl={4} style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}>
                                                {selectedPlan.includes(plan.id) &&
                                                    <CheckOutlined style={{ fontSize: 30 }} />
                                                }

                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={0} sm={0} md={2} lg={2} xl={2}></Col>
                                </Row>
                            ))}


                            <Row justify="center" style={{ marginBottom: 20 }}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ textAlign: "center" }}>
                                    <Button type="primary" size="large" className="cta-button"
                                        style={{ maxWidth: 400 }}
                                        onClick={() => getMyCopy()}
                                    >
                                        {t('button_getmycopy')}
                                    </Button>
                                </Col>
                            </Row>


                            <Row justify="center" style={{ marginBottom: 20, fontSize: 14 }}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Text style={{ fontSize: 10 }}>
                                        {t('upsell_3-signup_body')}
                                    </Text>
                                </Col>
                            </Row>

                            <Row justify="center" style={{ marginBottom: 40, fontSize: 14, cursor: "pointer" }}
                                onClick={() => noThankyou()}
                            >
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Text style={{ fontSize: 10, textDecoration: "underline", fontWeight: "500" }}
                                        onClick={() => noThankyou()}
                                    >
                                        {t('upsell_3-signup_skip')}
                                    </Text>
                                </Col>
                            </Row>

                        </Col>
                    </Col>
                </Row>
            }
        </React.Fragment >
    );
}

export default Level109Quiz;
